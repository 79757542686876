import React, { useState } from 'react';
import { FaMapMarkerAlt, FaRupeeSign } from "react-icons/fa";
import { IoMdCart } from "react-icons/io";
import { RiAdminFill } from "react-icons/ri";
import GoldRate from '../GoldRate/GoldRate';
import { Link } from 'react-router-dom';

function Uppernavbar() {
    const [showGoldRate, setShowGoldRate] = useState(false);

    return (
        <div className="bg-[#FAEF9F] shadow-md py-2 w-full transition-transform duration-300">
            <div className='container mx-auto flex justify-between items-center'>
                <div className='flex items-center space-x-4'>
                    <form className="relative">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                        </div>
                        <input type="search" id="default-search" className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-[#F9564E] focus:border-[#F9564E]" placeholder="Search Collections..." required />
                    </form>
                </div>

                <div className='flex space-x-8 text-gray-800 text-sm'>
                    <div
                        className='flex items-center space-x-2 cursor-pointer hover:text-yellow-700 transition relative group'
                        onMouseEnter={() => setShowGoldRate(true)}
                        onMouseLeave={() => setShowGoldRate(false)}
                    >
                        <FaRupeeSign className='text-lg' />
                        <p>Today's Rate</p>

                        {/* Gold Rate dropdown */}
                        {showGoldRate && (
                            <div className="absolute top-full mt-2 left-0 right-0 w-max bg-white p-4 shadow-lg border border-yellow-500 z-10 group-hover:block">
                                <GoldRate />
                            </div>
                        )}
                    </div>

                    <div className='flex items-center space-x-2 cursor-pointer hover:text-yellow-700 transition'>
                        <FaMapMarkerAlt className='text-lg' />
                        <p>Store Locator</p>
                    </div>
                    <div className='flex items-center space-x-2 cursor-pointer hover:text-yellow-700 transition'>
                        <IoMdCart className='text-lg' />
                        <p>My Cart</p>
                    </div>
                    <Link to="/admin_login">   <div className='flex items-center space-x-2 cursor-pointer hover:text-yellow-700 transition'>
                        <RiAdminFill className='text-lg' />
                        <p>My Account</p>
                    </div></Link>
                </div>
            </div>
        </div>
    );
}

export default Uppernavbar;
