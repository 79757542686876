import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FcCheckmark } from "react-icons/fc";
import { MdOutlineCurrencyRupee } from "react-icons/md";

function GoldRate() {
    const [goldrateList, setGoldrateList] = useState([]);
    const [lastUpdate, setLastUpdate] = useState('');

    useEffect(() => {
        fetchGoldRateList();

    }, []);

    const fetchGoldRateList = async () => {
        try {
            const response = await axios.get('http://localhost:8080/gold-rate/current');
            setGoldrateList(response.data);
            updateLastUpdateTime();
        } catch (error) {
            console.error("An error occurred while fetching the Gold Rate list:", error.message);
        }
    };

    const updateLastUpdateTime = () => {
        const now = new Date();
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        };
        const indianDate = now.toLocaleString('en-IN', options);
        setLastUpdate(indianDate);
    };

    return (
        <div className='p-4 w-96 mx-auto mt-20 bg-white border-4 border-[#EEB93C]'>
            <h2 className="text-center text-2xl font-bold text-gray-800 mt-8">
                Today's Metal Rates (Per gram)
            </h2>
            <p className="text-center text-lg text-gray-600 mt-2">
                Updated as of: {lastUpdate}
            </p>
            {goldrateList.map((goldrateItem, index) => (
                <div className='my-6 p-6 border border-[#C6016D] w-full sm:w-48' key={index}>
                    <h4 className='text-xl font-semibold mb-4 text-gray-700'>Rates</h4>
                    {[
                        { label: 'Gold 24 ct', value: goldrateItem.rate24K },
                        { label: 'Gold 23 ct', value: goldrateItem.rate23K },
                        { label: 'Gold 22 ct', value: goldrateItem.rate22K },
                        { label: 'Gold 18 ct', value: goldrateItem.rate18K },
                        { label: 'Silver (kg)', value: goldrateItem.silverRate },
                    ].map(({ label, value }) => (
                        <div className='flex items-center mb-4' key={label}>
                            <FcCheckmark className='text-[#F9564E] text-2xl mr-3' />
                            <span className='text-gray-700 font-medium'>{label} :</span>
                            <span className='ml-auto flex items-center text-gray-800'>
                                <MdOutlineCurrencyRupee className='text-lg mr-1' /> {value}
                            </span>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default GoldRate;
