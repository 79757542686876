import React from 'react';
import Deposit from './Deposit';
import Lakshmi from './Lakshmi';
import Sanchaya from './Sanchaya';

function SavingSchemes() {
  return (
    // <div className="relative  rounded-lg shadow-lg max-w-full mx-auto bg-white overflow-hidden">
    //   <div
    //     className="absolute inset-0 bg-center filter blur-md"
    //     style={{ backgroundImage: `url(${bgimg})` }}
    //   ></div>

    //   <div className="relative z-10 p-8 ">
    //     <div className="text-center mb-10">
    //       <h1 className="text-3xl font-semibold text-gray-800">Savarna - Rajat Deposit Scheme</h1>
    //       <p className="text-5xl font-bold text-[#CB983F] mt-2">Gold on Gold</p>
    //     </div>

    //     <div className="p-6  mb-10">
    //       <h6 className="text-xl font-semibold text-gray-800 mb-4">Terms and Conditions</h6>
    //       <ul className="list-disc list-inside space-y-2 text-gray-700">
    //         <li>You can get the Gold as Interest benefit under this scheme on 99.5% Pure Gold.</li>
    //         <li>At the time of opening the account, a minimum of 25 grams of gold deposit is required.</li>
    //         <li>The benefit under this scheme will be in the form of 4% interest for the 1st year.</li>
    //         <li>To avail this benefit, the customer is required to submit the gold purchase receipts, address proof, & passport size 2 photographs.</li>
    //         <li>To get the benefit of the scheme, the customers will be required to keep the gold with JBS for more than 180 days.</li>
    //         <li>Deposits or withdrawals from the account or giving benefits will be done in the form of 99.50% gold.</li>
    //         <li>The size and nature of the gold returned to the account holder will be different from the size and nature of the gold at the time of deposit.</li>
    //         <li>This scheme is available in Baramati, Phaltan, and Pandharpur Branches.</li>
    //         <li>For this scheme, while purchasing jewelry, making charges and GST will have to be paid separately.</li>
    //         <li>Interest earned under this scheme will be subject to TDS.</li>
    //         <li>The right to change, increase, or cancel the terms and conditions shall remain with Jyotichand Bhaichand Saraf & Sons Pvt Ltd.</li>
    //         <li>Baramati, Phaltan Branch under Baramati jurisdiction and Pandharpur Branch under Pandharpur jurisdiction.</li>
    //       </ul>
    //     </div>

    //     <div className="text-center mb-10">
    //       <h2 className="text-3xl font-semibold text-gray-800">Suvarna Sanchaya Yojana</h2>
    //       <p className="text-5xl font-bold text-[#CB983F] mt-2">
    //         In this scheme, the first instalment of Muhurta will be paid by JBS. The remaining 11 instalments will be paid by the customers.
    //       </p>
    //     </div>

    //     <div className="p-6 mb-10">
    //       <h6 className="text-xl font-semibold text-gray-800 mb-4">Terms and Conditions</h6>
    //       <ul className="list-disc list-inside space-y-2 text-gray-700">
    //       <li>Customers have to invest Rs. 500, Rs. 1000, or more within 11 consecutive months of instalments.</li>
    //       <li>After paying Rs. 1000 for 11 months, the maturity amount will be Rs. 12000 after one year, and you can make a purchase of Rs. 12000.</li>
    //       <li>10% discount will be given on the making charges of diamonds.</li>
    //       <li>To open this scheme, it is mandatory for customers to provide bank account details and a photo.</li>
    //       <li>The local bank only on the next fixed date of every month Cheques will be accepted. The date on which the amount of the Cheque the date of credit in the institution's bank account will be considered. If the Cheque is not cleared, the Cheque will not be credited back to the bank account. After that, the amount of the card has to be paid in cash.</li>                       
    //      <li>The amount of each instalment must be paid before the 10th of every month.</li>
    //      <li>If the saving Scheme is closed within the month, only the deposit amount can be withdrawn. No discount will be given after </li>
    //      <li>At the time of purchase, actual rates and making charges will be charged.</li>
    //      <li>If the card is misplaced by Customer after paying the amount, another saving scheme card will be issued after paying fifty rupees. </li>
    //      <li>Identity card, saving scheme card and all receipts has to be submitted at the time of purchase by customer. Similarly the amount cannot be Transferred to another person.</li>
    //      <li>If the customer don’t purchase / take benefit after completion of the plan period, the account shall be closed on the 360th day from the date of commencement of the plan without giving any discount the principal amount will be transferred into the customer's bank account.</li>
    //      <li> This  Scheme is  Not  applicable On  Purchase of Sone Vedhani , Coins &  Bullions </li>
    //      <li>Customer has to pay VAT, any other actual tax at the time of purchase. Similarly, if any or all of the rules, conditions, taxes etc. are imposed by the Central or State Government Municipalities in this scheme, it shall be paid. Responsibilities of users.</li>
    //      <li>This saving Scheme is completely different. It has nothing to do with past and future Gold Saving Schemes </li>
    //      <li>Jyotichand Bhaichand Saraf and Sons Pvt Ltd reserve the right to modify or change any of the above-mentioned terms and conditions as well as cancel them.</li>
    //    <li>Baramati, Phaltan Branch under Baramati jurisdiction and Pandharpur Branch under Pandharpur jurisdiction.
    //    </li>
    //     </ul>
    //     </div>

    //     <div className="text-center mb-10">
    //       <h2 className="text-3xl font-semibold text-gray-800">Suvarna Lakshmi Yojana</h2>
    //       <p className="text-xl font-bold text-[#CB983F] mt-2">Useful For Purchase of Coins, Bullions & Vedhani</p>
    //     </div>

    //     <div className="p-6 mb-10">
    //       <h6 className="text-xl font-semibold text-gray-800 mb-4">Terms and Conditions</h6>
    //       <ul className="list-disc list-inside space-y-2 text-gray-700">
    //       <li>After paying Rs. 10,000 in 10 months of Instalments the deposit amount will be  Rs 10,000  On deposit of Rs 10000 Added Benefit  of Rs 700 Will be Given By JBS</li>
    //       <li>This saving scheme is exclusively  for Gold  vadhani, coins, and chandi chorsa ( bullions) </li>
    //       <li>It is mandatory to submit photo and bank account information at the beginning of the scheme.</li>
    //       <li>Only Local Bank Cheque will be accepted on certain dates next month. The date on which the check amount is credited to the institution's bank account will be considered. If cheque is not distributed then, the said Cheque will not be refunded to the bank account. After that, the amount of the card has to be paid in cash.</li>
    //       <li>The amount of Instalment  must be paid  Before 10th of every Month</li>
    //       <li>Within 10 months only amount can be withdrawn up to the plan closure. Additional after the same period What discount will be given in exchange for the day.</li>
    //       <li>At the time of purchase, actual rates and making charges will be charged.</li>
    //       <li>If the card is misplaced by customer. After paying Ras 50, another saving will be issued. The same amount will not be refunded under any circumstances. </li>
    //       <li>Identity card, saving card and all receipts will have to be submitted at the time of purchase. Similarly, the address filled by you cannot be submitted in the name of another person for any reason.</li>
    //       <li>If the customer purchases after completion of the plan period, the account shall be closed on the 360th day from the date of commencement of the plan without giving any discount The principal amount will be transferred into the customer's bank account.</li>
    //       <li>Customers will have to pay VAT, VAT, any other actual tax at the time of purchase. Similarly, if any or all of the rules, conditions, taxes etc. are imposed by the Central or State Government Municipalities in this scheme, it shall be paid. Responsibilities of users.</li>
    //       <li>This saving plan should be completely excluded. It has nothing to do with past and future plans.</li>
    //       <li>Jyotichand Bhaichand Saraf and Sons Pvt ltd reserves the right to modify or change any of the above-mentioned terms and conditions as well as cancel them.</li>
    //       <li>Baramati, Phaltan Branch under Baramati jurisdiction and Pandharpur Branch under Pandharpur jurisdiction.
    //       </li>
    //     </ul>          
    //     </div>
    //   </div>
    // </div>
    <div>
    <Deposit/>
    <Lakshmi/>
    <Sanchaya/></div>
  );
}

export default SavingSchemes;
