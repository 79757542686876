import React from 'react';
import { MdAccessTimeFilled } from "react-icons/md";
import { BiSolidPhoneCall } from "react-icons/bi";
import { FaMapMarkerAlt, FaFacebook, FaInstagramSquare, FaTwitter } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";

const ContactSection = ({ address, mobile, email, hours, mapSrc }) => {
    return (
        <div style={{ fontFamily: 'Poppins, sans-serif' }}>
            <section className="bg-gray-50" id="contact">
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 lg:py-2">
                    
                    <div className="flex items-stretch justify-center">
                        <div className="grid md:grid-cols-2">
                            <div className="h-full pr-6">
                                <p className="mt-3 mb-12 text-lg text-gray-600">
                                    Your Perfect Jewelry Experience Awaits!
                                    Whether you need help choosing an engagement ring, selecting a special gift, or would like personalized recommendations, our JBS client experts are here to guide you every step of the way.
                                </p>
                                <ul className="mb-6 md:mb-0">
                                    <li className="flex">
                                        <div className="flex h-10 w-10 items-center justify-center rounded bg-[#C6016D] text-gray-50">
                                            <FaMapMarkerAlt />
                                        </div>
                                        <div className="ml-4 mb-4">
                                            <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900">Our Address</h3>
                                            <p className="text-gray-600">{address}</p>
                                        </div>
                                    </li>
                                    <li className="flex">
                                        <div className="flex h-10 w-10 items-center justify-center rounded bg-[#C6016D] text-gray-50">
                                            <BiSolidPhoneCall />
                                        </div>
                                        <div className="ml-4 mb-4">
                                            <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900">Contact</h3>
                                            <p className="text-gray-600">Mobile: {mobile}</p>
                                            <p className="text-gray-600">Mail: {email}</p>
                                        </div>
                                    </li>
                                    <li className="flex">
                                        <div className="flex h-10 w-10 items-center justify-center rounded bg-[#C6016D] text-gray-50">
                                            <MdAccessTimeFilled />
                                        </div>
                                        <div className="ml-4 mb-4">
                                            <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900">Working Hours</h3>
                                            {hours.map((hour, index) => (
                                                <p key={index} className="text-gray-600">{hour}</p>
                                            ))}
                                        </div>
                                    </li>
                                </ul>
                                <div>
                                    <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900">Connect With Us</h3>
                                    <div className='flex space-x-4'>
                                        <div className="flex h-10 w-10 items-center justify-center rounded bg-[#C6016D] text-gray-50">
                                            <IoLogoWhatsapp />
                                        </div>
                                        <div className="flex h-10 w-10 items-center justify-center rounded bg-[#C6016D] text-gray-50">
                                            <FaFacebook />
                                        </div>
                                        <div className="flex h-10 w-10 items-center justify-center rounded bg-[#C6016D] text-gray-50">
                                            <FaInstagramSquare />
                                        </div>
                                        <div className="flex h-10 w-10 items-center justify-center rounded bg-[#C6016D] text-gray-50">
                                            <FaTwitter />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card h-fit max-w-6xl p-5 md:p-12" id="form">
                                <h2 className="mb-4 text-2xl font-bold">Ready to Get Started?</h2>
                                <form id="contactForm">
                                    <div className="mb-6">
                                        <div className="mx-0 mb-4">
                                            <label htmlFor="name" className="pb-1 text-xs uppercase tracking-wider">Name</label>
                                            <input type="text" id="name" placeholder="Your name" className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300" name="name" />
                                        </div>
                                        <div className="mx-0 mb-4">
                                            <label htmlFor="email" className="pb-1 text-xs uppercase tracking-wider">Email</label>
                                            <input type="email" id="email" placeholder="Your email address" className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300" name="email" />
                                        </div>
                                        <div className="mx-0 mb-4">
                                            <label htmlFor="mobile" className="pb-1 text-xs uppercase tracking-wider">Mobile</label>
                                            <input type="tel" id="mobile" placeholder="+91 Your mobile number" className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300" name="mobile" />
                                        </div>
                                        <div className="mx-0 mb-4">
                                            <label htmlFor="textarea" className="pb-1 text-xs uppercase tracking-wider">Message</label>
                                            <textarea id="textarea" name="textarea" cols="30" rows="5" placeholder="Write your message..." className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300"></textarea>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" className="w-full bg-[#C6016D] text-white px-6 py-3 font-xl rounded-md sm:mb-0">Send Message</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="flex justify-center">
                <iframe
                    src={mapSrc}
                    width="100%"
                    height="350"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                />
            </div>
        </div>
    );
};

export default ContactSection;
