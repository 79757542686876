import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../image/JB-eng-logo.png';
import navbg from '../../image/Untitled-1.jpg';

function Navbar() {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const closeDropdown = () => {
        setDropdownOpen(false);
    };

    return (
        <div className='relative'>
            <div
                className='bg-cover bg-center h-24 flex items-center justify-between px-16 relative opacity-80 shadow-md'
                style={{ backgroundImage: `url(${navbg})` }}
            >
                <div className='flex items-center'>
                    <Link to="/" onClick={closeDropdown}>
                        <img src={logo} alt="JBS Logo" className='h-20' />
                    </Link>
                </div>
                <div>
                    <ul className='flex space-x-16 text-white'>
                        <Link to="/about" onClick={closeDropdown}>
                            <li className='uppercase font-semibold tracking-wide transition duration-300 hover:text-yellow-500 text-lg relative group'>
                                About Us
                                <span className="absolute left-1/2 bottom-0 w-0 h-0.5 bg-yellow-500 transition-all duration-300 ease-out group-hover:w-full group-hover:left-0"></span>
                            </li>
                        </Link>

                        <li 
                            className='uppercase font-semibold tracking-wide transition duration-300 hover:text-yellow-500 text-lg relative group'
                            onClick={toggleDropdown}
                        >
                            Saving Schemes
                            <span className="absolute left-1/2 bottom-0 w-0 h-0.5 bg-yellow-500 transition-all duration-300 ease-out group-hover:w-full group-hover:left-0"></span>
                            <ul className={`absolute left-0 w-96 mt-10 p-2 z-90 bg-white text-black rounded-lg shadow-lg transition-all duration-300 ease-out origin-top transform ${dropdownOpen ? 'opacity-100 scale-y-100' : 'opacity-0 scale-y-0'}`}>
                                <li className='py-1 px-4 rounded text-yellow-600 transition-transform duration-300 ease-in-out hover:scale-105'>
                                    <Link to="/savarna-rajat_deposit_scheme" onClick={closeDropdown}>
                                        Savarna - Rajat Deposit Scheme
                                        <span className="absolute left-1/2 bottom-0 w-0 h-0.5 bg-yellow-500 transition-all duration-300 ease-out group-hover:w-full group-hover:left-0"></span>
                                    </Link>
                                </li>
                                <li className='py-1 px-4 rounded text-yellow-600 transition-transform duration-300 ease-in-out hover:scale-105'>
                                    <Link to="/suvarna_sanchaya_yojana" onClick={closeDropdown}>
                                        Suvarna Sanchaya Yojana
                                        <span className="absolute left-1/2 bottom-0 w-0 h-0.5 bg-yellow-500 transition-all duration-300 ease-out group-hover:w-full group-hover:left-0"></span>
                                    </Link>
                                </li>
                                <li className='py-1 px-4  rounded text-yellow-600 transition-transform duration-300 ease-in-out hover:scale-105'>
                                    <Link to="/suvarna_lakshmi_yojana" onClick={closeDropdown}>
                                        Suvarna Lakshmi Yojana
                                        <span className="absolute left-1/2 bottom-0 w-0 h-0.5 bg-yellow-500 transition-all duration-300 ease-out group-hover:w-full group-hover:left-0"></span>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <Link to="/JBSapp" onClick={closeDropdown}>
                            <li className='uppercase font-semibold tracking-wide transition duration-300 hover:text-yellow-500 text-lg relative group'>
                                JBS Gold App
                                <span className="absolute left-1/2 bottom-0 w-0 h-0.5 bg-yellow-500 transition-all duration-300 ease-out group-hover:w-full group-hover:left-0"></span>
                            </li>
                        </Link>
                        <Link to="/collections" onClick={closeDropdown}>
                            <li className='uppercase font-semibold tracking-wide transition duration-300 hover:text-yellow-500 text-lg relative group'>
                                Collections
                                <span className="absolute left-1/2 bottom-0 w-0 h-0.5 bg-yellow-500 transition-all duration-300 ease-out group-hover:w-full group-hover:left-0"></span>
                            </li>
                        </Link>
                        <Link to="/contact" onClick={closeDropdown}>
                            <li className='uppercase font-semibold tracking-wide transition duration-300 hover:text-yellow-500 text-lg relative group'>
                                Contact Us
                                <span className="absolute left-1/2 bottom-0 w-0 h-0.5 bg-yellow-500 transition-all duration-300 ease-out group-hover:w-full group-hover:left-0"></span>
                            </li>
                        </Link>
                    </ul>
                </div>
            </div>
            <div className='absolute bottom-0 left-0 w-full h-2'>
                <div className='h-full w-full' style={{
                    background: `linear-gradient(to right, #CB983F, #FAD562, #FAEF9F, #D4AF61, #CB983F)`
                }}>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
