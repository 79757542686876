import React, { useState } from 'react';
import Baramati from './Location/Baramati';
import Phaltan from './Location/Phaltan';
import Bhigwan from './Location/Bhigwan';

const Contact = () => {
  const [selectedBranch, setSelectedBranch] = useState('baramati');

  const renderBranch = () => {
    switch (selectedBranch) {
      case 'baramati':
        return <Baramati />;
      case 'phaltan':
        return <Phaltan />;
      case 'bhigwan':
        return <Bhigwan />;
      default:
        return <Baramati />;
    }
  };

  return (
    <section className="bg-gray-50" id="contact">

      <div className="mx-auto max-w-7xl  px-4 py-16 sm:px-6 lg:px-8 lg:py-20">
        <p className="text-base  text-center font-semibold uppercase tracking-wide text-gray-600">
          Contact Us
        </p>
        <h2 className="font-heading mb-4 text-center font-bold tracking-tight text-[#C6016D] text-3xl sm:text-5xl">
          Get in Touch
        </h2>


        <div className="flex gap-10 justify-center mb-6">
          <p
            className={`px-2 py-2 cursor-pointer ${selectedBranch === 'baramati' ? 'text-[#C6016D] font-semibold' : 'text-gray-600'}`}
            onClick={() => setSelectedBranch('baramati')}
          >
            Baramati
          </p>
          <p
            className={`px-2 py-2 cursor-pointer ${selectedBranch === 'phaltan' ? 'text-[#C6016D] font-semibold' : 'text-gray-600'}`}
            onClick={() => setSelectedBranch('phaltan')}
          >
            Phaltan
          </p>
          <p
            className={`px-2 py-2 cursor-pointer ${selectedBranch === 'bhigwan' ? 'text-[#C6016D] font-semibold' : 'text-gray-600'}`}
            onClick={() => setSelectedBranch('bhigwan')}
          >
            Bhigwan
          </p>
        </div>

        {renderBranch()}
      </div>

    </section>
  );
};

export default Contact;
