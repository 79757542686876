import React from 'react'
import bgimg from '../../image/JBS_Pattern.svg';

function Sanchaya() {
    return (
        <div className="relative  rounded-lg shadow-lg max-w-full mx-auto bg-white overflow-hidden">
            <div
                className="absolute inset-0 bg-center filter blur-md"
                style={{ backgroundImage: `url(${bgimg})` }}
            ></div>
            <div className="relative z-10 p-8 ">
                <div className="text-center mb-10">
                    <h2 className="text-3xl font-semibold text-gray-800">Suvarna Sanchaya Yojana</h2>
                    <p className="text-xl font-bold text-[#CB983F] mt-2">
                        In this scheme, the first instalment of Muhurta will be paid by JBS. The remaining 11 instalments will be paid by the customers.
                    </p>
                </div>
                <div className="p-6 mb-10">
                    <h6 className="text-xl font-semibold text-gray-800 mb-4">Terms and Conditions</h6>
                    <ul className="list-disc list-inside space-y-2 text-gray-700">
                        <li>Customers have to invest Rs. 500, Rs. 1000, or more within 11 consecutive months of instalments.</li>
                        <li>After paying Rs. 1000 for 11 months, the maturity amount will be Rs. 12000 after one year, and you can make a purchase of Rs. 12000.</li>
                        <li>10% discount will be given on the making charges of diamonds.</li>
                        <li>To open this scheme, it is mandatory for customers to provide bank account details and a photo.</li>
                        <li>The local bank only on the next fixed date of every month Cheques will be accepted. The date on which the amount of the Cheque the date of credit in the institution's bank account will be considered. If the Cheque is not cleared, the Cheque will not be credited back to the bank account. After that, the amount of the card has to be paid in cash.</li>
                        <li>The amount of each instalment must be paid before the 10th of every month.</li>
                        <li>If the saving Scheme is closed within the month, only the deposit amount can be withdrawn. No discount will be given after </li>
                        <li>At the time of purchase, actual rates and making charges will be charged.</li>
                        <li>If the card is misplaced by Customer after paying the amount, another saving scheme card will be issued after paying fifty rupees. </li>
                        <li>Identity card, saving scheme card and all receipts has to be submitted at the time of purchase by customer. Similarly the amount cannot be Transferred to another person.</li>
                        <li>If the customer don’t purchase / take benefit after completion of the plan period, the account shall be closed on the 360th day from the date of commencement of the plan without giving any discount the principal amount will be transferred into the customer's bank account.</li>
                        <li> This  Scheme is  Not  applicable On  Purchase of Sone Vedhani , Coins &  Bullions </li>
                        <li>Customer has to pay VAT, any other actual tax at the time of purchase. Similarly, if any or all of the rules, conditions, taxes etc. are imposed by the Central or State Government Municipalities in this scheme, it shall be paid. Responsibilities of users.</li>
                        <li>This saving Scheme is completely different. It has nothing to do with past and future Gold Saving Schemes </li>
                        <li>Jyotichand Bhaichand Saraf and Sons Pvt Ltd reserve the right to modify or change any of the above-mentioned terms and conditions as well as cancel them.</li>
                        <li>Baramati, Phaltan Branch under Baramati jurisdiction and Pandharpur Branch under Pandharpur jurisdiction.
                        </li>
                    </ul>
                </div>

            </div>
        </div>)
}

export default Sanchaya