import React from 'react';
import appbg from '../../image/pexels-kenzero14-9420694.jpg';
import goldmobile from '../../image/JB-August-12 copy.png';
import playstore from '../../image/appplaystore-removebg-preview.png';

function GoldApp() {
    return (
        <div className="flex flex-col md:flex-row justify-between items-center p-5 md:p-10 mt-16 md:mt-32 space-y-10 md:space-y-0">
            {/* Left Section - Text Content */}
            <div
                className="bg-cover bg-center w-full md:w-2/3 flex items-center justify-start px-6 md:px-10 py-8 relative shadow-lg rounded-lg transition duration-500 hover:scale-105"
                style={{ backgroundImage: `url(${appbg})` }}
            >
                <div className="bg-white bg-opacity-80 p-6 md:p-8 rounded-lg backdrop-blur-md shadow-md">
                    <h2 className="text-3xl md:text-4xl font-bold mb-4 md:mb-6 text-gray-800">Gold Digital App</h2>
                    <p className="mb-4 md:mb-6 text-gray-700 text-base md:text-lg">
                        Discover a seamless experience with our digital gold app, where luxury meets technology. Enjoy features like:
                    </p>
                    <ul className="text-gray-700 space-y-2 list-disc pl-4 text-sm md:text-base">
                        <li>Physical delivery at your doorstep.</li>
                        <li>Start with an investment as low as Rs 1.</li>
                        <li>Use digital gold as collateral for online loans.</li>
                        <li>Guaranteed 24K purity (99.5% for Safe Gold, 999.9 for MMTC PAMP).</li>
                        <li>100% insured purchases stored securely.</li>
                        <li>Option to exchange for physical jewellery, coins, or bullion.</li>
                    </ul>
                </div>
            </div>

            {/* Right Section - Image Content */}
            <div className="bg-gradient-to-r from-[#B90065] to-[#E91E63] w-full md:w-1/2 h-64 p-6 md:p-10 flex flex-col justify-center items-center shadow-lg ">
                <div className="flex flex-col md:flex-row justify-center items-center space-y-6 md:space-y-0 md:space-x-6">
                    <img src={goldmobile} alt="Gold App" className="w-64 md:w-80 h-auto mb-4 md:mb-0 animate-fadeInUp md:mt-0 mt-64 " />
                    <img src={playstore} alt="Play Store" className="w-32 h-auto animate-fadeInUp" />
                </div>
            </div>
        </div>
    );
}

export default GoldApp;
