// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import React from 'react'

function Homepage() {
    // const [slides, setSlides] = useState([]);
    // const [loading, setLoading] = useState(true);
    // const [error, setError] = useState('');

    // useEffect(() => {
    //     axios
    //         .get('your-api-endpoint')
    //         .then((response) => {
    //             setSlides(response.data);
    //             setLoading(false);
    //         })
    //         .catch((error) => {
    //             setError('Error fetching slides');
    //             setLoading(false);
    //         });
    // }, []);

    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 1000,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     autoplaySpeed: 3000,
    //     fade: true,
    //     cssEase: 'linear',
    //     pauseOnHover: true,
    // };

    // if (loading) {
    //     return <div>Loading...</div>;
    // }

    // if (error) {
    //     return <div>{error}</div>;
    // }

    return (

<div>
    
</div>

        // <div className="homepage-container">
        //     <Slider {...settings}>
        //         {slides.map((slide, index) => (
        //             <div key={index} className="carousel-slide relative">
        //                 <div className="carousel-content relative">
        //                     <img
        //                         src={slide.image}
        //                         alt={`Slide ${index}`}
        //                         className="slide-image w-full h-screen object-cover"
        //                     />
        //                     <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        //                         <div className="text-center text-white p-6">
        //                             <h2 className="text-5xl font-extrabold mb-4 animate-fade-in-up">
        //                                 {slide.title}
        //                             </h2>
        //                             <p className="text-lg md:text-xl mb-8 animate-fade-in-up">
        //                                 {slide.description}
        //                             </p>
        //                             <a
        //                                 href={slide.link}
        //                                 className="inline-block bg-gold hover:bg-gold-dark text-white py-3 px-6 rounded-full text-lg font-semibold transition duration-300"
        //                             >
        //                                 Shop Now
        //                             </a>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         ))}
        //     </Slider>
        // </div>
    );
}

export default Homepage;
