import React from 'react';
import logo from '../../image/JB-March-01 copy.png';
import pay1 from '../../image/pay1.png';
import pay2 from '../../image/pay2.png';
import pay3 from '../../image/pay4.png';
import pay4 from '../../image/pay5.png';
import social1 from '../../image/facebook-color.svg';
import social2 from '../../image/whatsapp.svg';
import social3 from '../../image/instagram.svg';
import social4 from '../../image/twitter.svg';
import social5 from '../../image/youtube-round-2.svg';
import footbg from '../../image/Untitled-1.jpg';

function Footer() {
    return (
        <footer
            className="relative text-white py-10 bg-cover bg-center bg-no-repeat bg-fixed"
            style={{ backgroundImage: `url(${footbg})` }}
        >
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className=" relative z-10 container mx-auto px-4 grid grid-cols-1 md:grid-cols-4 gap-12">
                {/* Logo Section */}
                <div className="text-center md:text-left">
                    <img src={logo} alt="JBS Logo" className="w-40 mx-auto md:mx-0 mb-6" />
                </div>

                {/* Branches Section */}
                <div className="col-span-2">
                    {/* <h2 className="text-2xl font-semibold mb-6 text-center md:text-center text-gold">Our Branches</h2> */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                        <div>
                            <h3 className="font-bold text-lg text-gold py-4">Baramati</h3>
                            <p className="text-gray-300">Station Road, Baramati - 413102 Pune (MS) India.</p>
                            <p className="text-gray-300">+91 77458 46633</p>
                            {/* <p className="text-gray-300">support@jbsjewellers.com</p> */}
                        </div>
                        <div>
                            <h3 className="font-bold text-lg text-gold py-4">Phaltan</h3>
                            <p className="text-gray-300">Near Bus Stand, Phaltan - 415523, Maharashtra</p>
                            <p className="text-gray-300">+91 73830 36076</p>
                            {/* <p className="text-gray-300">support@jbsjewellers.com</p> */}
                        </div>
                        <div>
                            <h3 className="font-bold text-lg text-gold py-4">Bhigwan</h3>
                            <p className="text-gray-300">Behind Old Bus Stand, Bhigwan - 413105, Maharashtra</p>
                            <p className="text-gray-300">+91 90492 24331</p>
                            {/* <p className="text-gray-300">support@jbsjewellers.com</p> */}
                        </div>
                    </div>
                </div>

                {/* Quick Links Section */}
                <div>
                    <h2 className="text-2xl font-semibold mb-6 text-center md:text-left text-gold">Quick Links</h2>
                    <nav>
                        <ul className="text-gray-300 space-y-4">
                            <li className="hover:text-gold transition duration-200"> About JBS</li>
                            <li className="hover:text-gold transition duration-200"> Terms and Conditions</li>
                            <li className="hover:text-gold transition duration-200"> Contact</li>
                            <li className="hover:text-gold transition duration-200"> Privacy Policy</li>
                            <li className="hover:text-gold transition duration-200"> CSR Policy</li>
                            <li className="hover:text-gold transition duration-200"> Schemes</li>
                        </ul>
                    </nav>
                </div>
            </div>

            {/* Footer Bottom */}
            <div className="bg-gray-800 py-6 mt-10">
                <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
                    {/* Copyright */}
                    <div className="text-gray-400 mb-6 md:mb-0 text-center md:text-left">
                        <p>© 2024 JBS. All rights reserved.</p>
                        <p>Powered by <a href="https://www.businessmantra.info/" className="text-gray-200 hover:text-gold">Business Mantra</a></p>
                    </div>

                    {/* Payment Methods */}
                    <div>
                        <h2 className="text-lg font-semibold mb-4 text-gold">Payment Methods</h2>
                        <div className="flex space-x-4">
                            <img src={pay1} alt="Payment Method 1" className="w-10 h-auto" />
                            <img src={pay2} alt="Payment Method 2" className="w-10 h-auto" />
                            <img src={pay3} alt="Payment Method 3" className="w-10 h-auto" />
                            <img src={pay4} alt="Payment Method 4" className="w-10 h-auto" />
                        </div>
                    </div>

                    {/* Social Media */}
                    <div>
                        <h2 className="text-lg font-semibold mb-4 text-gold">Follow Us</h2>
                        <div className="flex space-x-4">
                            <img src={social1} alt="Facebook" className="w-8 h-auto hover:scale-110 transition duration-200" />
                            <img src={social2} alt="WhatsApp" className="w-8 h-auto hover:scale-110 transition duration-200" />
                            <img src={social3} alt="Instagram" className="w-8 h-auto hover:scale-110 transition duration-200" />
                            <img src={social4} alt="Twitter" className="w-8 h-auto hover:scale-110 transition duration-200" />
                            <img src={social5} alt="YouTube" className="w-8 h-auto hover:scale-110 transition duration-200" />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
