import React from 'react';
import bgimg from '../../image/JBS_Pattern.svg';
import founders from '../../image/JBS_Founders.png';
import img1 from '../../image/gold-price.png';
import img2 from '../../image/money.png';
import img3 from '../../image/lakshmi-charan-paduka.png';
import img4 from '../../image/diamond.png';
import underline from '../../image/underline.png';

function About() {
  return (
    <div className="relative overflow-hidden">
      <div
        className="absolute inset-0 bg-center filter blur-md opacity-80"
        style={{ backgroundImage: `url(${bgimg})` }}
      ></div>

      {/* Our Story Section */}
      <div className="relative  flex flex-col items-center justify-center py-16 px-6 md:px-16 space-y-8">
        <div className="md:w-2/3 w-full text-center animate-fadeIn">
          <img
            src={founders}
            alt="Founders of Jotichand Bhaichand Saraf"
            className="w-full h-auto mx-auto"
          />
        </div>

        <div className="md:w-2/3 w-full text-center md:text-left py-12 space-y-4">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-800 tracking-wider animate-slideInRight">
            Our Story
          </h1>
          <img src={underline} alt="Underline graphic" className="w-64 mx-auto md:mx-0 my-4" />
          <p className="text-gray-700 text-md md:text-lg leading-relaxed animate-fadeIn">
            Since 1812, the renowned firm in the state, Jotichand Bhaichand Saraf, has established a unique relationship with customers due to its purity and reliability, leaving a mark of beauty and trust in the golden era. We provide our customers with BIS hallmark certification for 22, 23, 23.5, and 24-carat gold jewelry. J.B.S. offers a wide array of choices in jewelry made of gold, silver, platinum, diamonds, and gemstones, emphasizing regional and handcrafted designs. Our goal is to provide customers with purity, a variety of samples, and authentic service, which is the essence of our business and principles. Thus, J.B.S. has made remarkable progress in various fields to date. Through purchases from our grand showroom, we are providing customers with a special experience and assurance. Our aim is not just business but to deliver impeccable, pure, and quality service to our customers.
          </p>
        </div>
      </div>

      {/* Specialties Section */}
      <div className="relative z-10 py-16 px-6 md:px-16 bg-gray-50">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-12 animate-fadeIn tracking-wide">
          Our Specialties
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Specialty Card */}
          <div className="group text-center p-6 bg-white shadow-lg hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-300 rounded-xl">
            <img
              src={img1}
              alt="Gold Savings Scheme"
              className="w-24 h-24 mx-auto mb-4 group-hover:animate-bounce"
            />
            <h3 className="text-xl font-semibold text-gray-800">
              Gold Savings Scheme
            </h3>
            <p className="text-gray-600 mt-2 text-sm">
              Plan your future with our exclusive gold savings schemes.
            </p>
          </div>

          {/* Specialty Card */}
          <div className="group text-center p-6 bg-white shadow-lg hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-300 rounded-xl">
            <img
              src={img2}
              alt="Gold-Silver Scheme"
              className="w-24 h-24 mx-auto mb-4 group-hover:animate-bounce"
            />
            <h3 className="text-xl font-semibold text-gray-800">
              Gold-Silver Scheme
            </h3>
            <p className="text-gray-600 mt-2 text-sm">
              Avail dual benefits with our gold-silver combo schemes.
            </p>
          </div>

          {/* Specialty Card */}
          <div className="group text-center p-6 bg-white shadow-lg hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-300 rounded-xl">
            <img
              src={img3}
              alt="Gold-Lakshmi Scheme"
              className="w-24 h-24 mx-auto mb-4 group-hover:animate-bounce"
            />
            <h3 className="text-xl font-semibold text-gray-800">
              Gold-Lakshmi Scheme
            </h3>
            <p className="text-gray-600 mt-2 text-sm">
              Invest in prosperity with our Gold-Lakshmi schemes.
            </p>
          </div>

          {/* Specialty Card */}
          <div className="group text-center p-6 bg-white shadow-lg hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-300 rounded-xl">
            <img
              src={img4}
              alt="Diamond Scheme"
              className="w-24 h-24 mx-auto mb-4 group-hover:animate-bounce"
            />
            <h3 className="text-xl font-semibold text-gray-800">
              Diamond Scheme
            </h3>
            <p className="text-gray-600 mt-2 text-sm">
              Shine brighter with our exclusive diamond schemes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
