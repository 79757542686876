import React from 'react';
import ContactSection from './ContactSection'; 

const Bhigwan = () => (
    <ContactSection 
        address="Mahalaxmi Complex, Behind Old Bus Stand, Bhigwan - 413105, Bhigwan, Maharashtra"
        mobile="+91 9049224331"
        email="JBS@gmail.com"
        hours={[
            "Monday - Saturday: 9:00 am - 8:00 pm",
            "Sunday: Closed"
        ]}
        mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d120788.54933715938!2d74.45173837704443!3d18.186799859171687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc3a3c34c0b2f3b%3A0x68b098eeff9dfc9a!2sMahalaxmi%20Complex!5e0!3m2!1sen!2sin!4v1693424728402!5m2!1sen!2sin"
    />
);

export default Bhigwan;
