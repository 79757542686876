import React from 'react';
import ContactSection from './ContactSection'; 

const Baramati = () => (
    <ContactSection 
        address="Jayashri Complex, Station Road, Gandhi Chowk, Baramati - 413102, Baramati, Maharashtra"
        mobile="+91 7745846633"
        email="JBS@gmail.com"
        hours={[
            "Monday - Saturday: 9:00 am - 8:00 pm",
            "Sunday: Closed"
        ]}
        mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d121322.37537538029!2d74.48987965623057!3d18.148971961167955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc3a03f04fd34ed%3A0x2b62fcb9fea6bf3d!2sM%2Fs%20Jyotichand%20Bhaichand%20Saraf%20and%20Sons%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1693424637405!5m2!1sen!2sin"
    />
);

export default Baramati;
