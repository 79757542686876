import React from 'react';
import { FaGetPocket, FaCrown } from "react-icons/fa";
import { MdOutlineSecurity } from "react-icons/md";
import { BsTransparency } from "react-icons/bs";
import { SiLiquibase } from "react-icons/si";
import { GiGoldBar } from "react-icons/gi";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import dgappmobile from '../../image/JB-August-12 copy.png';
import dgapp1 from '../../image/dgo1.jpg';
import dgapp2 from '../../image/dgo2.jpg';
import dgapp3 from '../../image/dgo3.jpg';
import dgapp4 from '../../image/dgo4.jpg';
import dgapp5 from '../../image/dgo5.jpg';
import bgforapp from '../../image/dgbackground.jpg';
import logo from '../../image/JB-Dec-23-35.png';
import bgimg from '../../image/JBS_Pattern.svg';

function DigitalGold() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        fade: true,
    };

    return (
        <div className="bg-gray-50">
            {/* Hero Section */}
            <div
                className="bg-cover bg-center text-white py-20 px-6"
                style={{ backgroundImage: `url(${bgforapp})`, backgroundBlendMode: 'overlay' }}
            >
                <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
                    <div className="animate__animated animate__fadeInLeft">
                        <img src={dgappmobile} alt="Mobile preview of Digital Gold App" className="h-auto w-80 mx-auto md:w-96" />
                    </div>
                    <div className="text-lg space-y-6 animate__animated animate__fadeInRight">
                        <h1 className="text-4xl font-bold text-yellow-700 mb-4">What is Digital Gold?</h1>
                        <p className="leading-relaxed text-gray-800">
                            Digital gold refers to a digital investment platform that allows individuals to buy, sell, and hold gold electronically. It enables investors to own fractions of physical gold without needing to store or manage the physical metal.
                        </p>
                        <p className="leading-relaxed text-gray-800">
                            Physical gold holds its own advantages when it comes to obtaining loans. Unlike digital gold, physical gold allows borrowers to secure larger loans more easily due to its tangible nature.
                        </p>
                        {/* Call to Action Button */}
                        <button className="mt-4 px-6 py-2 bg-yellow-500 text-white font-semibold rounded-md hover:bg-yellow-600 transition duration-300">
                            Learn More
                        </button>
                    </div>
                </div>
            </div>

            {/* Benefits Section */}
            <div className="container mx-auto py-16 px-6">
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-10">Benefits of Investing in Digital Gold</h2>
                <ul className="space-y-4 text-gray-700">
                    <li className="flex items-start gap-4 hover:scale-105 transition transform duration-300">
                        <FaGetPocket className="text-yellow-500 text-2xl" />
                        You can take physical delivery of the gold at your doorstep.
                    </li>
                    <li className="flex items-start gap-4 hover:scale-105 transition transform duration-300">
                        <FaGetPocket className="text-yellow-500 text-2xl" />
                        You can invest an amount as low as Re.1.
                    </li>
                    <li className="flex items-start gap-4 hover:scale-105 transition transform duration-300">
                        <FaGetPocket className="text-yellow-500 text-2xl" />
                        Digital Gold can be used as collateral for online loans.
                    </li>
                    <li className="flex items-start gap-4 hover:scale-105 transition transform duration-300">
                        <FaGetPocket className="text-yellow-500 text-2xl" />
                        Digital Gold is genuine with 24K purity for Safe Gold and 999.9 for MMTC PAMP.
                    </li>
                    <li className="flex items-start gap-4 hover:scale-105 transition transform duration-300">
                        <FaGetPocket className="text-yellow-500 text-2xl" />
                        Your purchase is stored safely and 100% insured.
                    </li>
                    <li className="flex items-start gap-4 hover:scale-105 transition transform duration-300">
                        <FaGetPocket className="text-yellow-500 text-2xl" />
                        You can exchange digital gold for physical jewellery or bullion.
                    </li>
                </ul>
            </div>

            {/* Why Invest Section */}
            <div className="bg-gray-50 py-16 px-6">
                <div className="container mx-auto space-y-8">
                    <h2 className="text-3xl font-bold text-center text-gray-800 mb-10">Why Invest in Digital Gold with JBS?</h2>
                    <p className="leading-relaxed text-gray-700">
                        At JBS, we believe that digital gold is the future of investing. With a mission of empowering the Indian workforce through financial wellness, we are committed to making it easier to invest in various financial tools. Digital gold allows for small, secure investments in one of the world's most valuable assets.
                    </p>
                    <p className="leading-relaxed text-gray-700">
                        Whether you are a seasoned investor or just starting, digital gold offers a secure way to invest in gold. Start today and take control of your financial future!
                    </p>
                </div>
            </div>

            {/* Carousel Section */}
            <div className="bg-slate-500 py-16 relative flex flex-col md:flex-row justify-center items-center gap-10">
                <div className="absolute inset-0 bg-center filter blur-sm opacity-90" style={{ backgroundImage: `url(${bgimg})` }}></div>
                <div className="relative w-full md:w-96 p-4 rounded-lg border bg-white shadow-lg">
                    <Slider {...settings} className="overflow-hidden">
                        <div>
                            <img src={dgapp1} alt="App Screenshot 1" className="w-full h-96 object-cover rounded-md" loading="lazy" />
                        </div>
                        <div>
                            <img src={dgapp2} alt="App Screenshot 2" className="w-full h-96 object-cover rounded-md" loading="lazy" />
                        </div>
                        <div>
                            <img src={dgapp3} alt="App Screenshot 3" className="w-full h-96 object-cover rounded-md" loading="lazy" />
                        </div>
                        <div>
                            <img src={dgapp4} alt="App Screenshot 4" className="w-full h-96 object-cover rounded-md" loading="lazy" />
                        </div>
                        <div>
                            <img src={dgapp5} alt="App Screenshot 5" className="w-full h-96 object-cover rounded-md" loading="lazy" />
                        </div>
                    </Slider>
                </div>

                <div className="relative flex flex-col items-center justify-center text-center z-10">
                    <img src={logo} alt="JBS Logo" className="w-48 h-auto mb-6" />
                    <GiGoldBar className="text-white text-6xl mt-4" />
                    <h1 className="text-white text-3xl font-bold">JBS Digital Gold App</h1>
                </div>
            </div>

            {/* Features Section */}
            <div className="container mx-auto py-16 px-6">
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-10">Features of Digital Gold</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
                    <div className="space-y-4 transform transition duration-300 hover:scale-105">
                        <FaGetPocket className="text-yellow-500 text-5xl mx-auto" />
                        <h3 className="text-xl font-bold text-gray-800">Convenience</h3>
                        <p className="text-gray-600">Buy, sell, and hold gold electronically from anywhere, anytime.</p>
                    </div>
                    <div className="space-y-4 transform transition duration-300 hover:scale-105">
                        <FaCrown className="text-yellow-500 text-5xl mx-auto" />
                        <h3 className="text-xl font-bold text-gray-800">Fractional Ownership</h3>
                        <p className="text-gray-600">Own as little as Re.1 worth of gold and build your assets over time.</p>
                    </div>
                    <div className="space-y-4 transform transition duration-300 hover:scale-105">
                        <MdOutlineSecurity className="text-yellow-500 text-5xl mx-auto" />
                        <h3 className="text-xl font-bold text-gray-800">Security</h3>
                        <p className="text-gray-600">Your gold is securely stored and fully insured, giving you peace of mind.</p>
                    </div>
                    <div className="space-y-4 transform transition duration-300 hover:scale-105">
                        <BsTransparency className="text-yellow-500 text-5xl mx-auto" />
                        <h3 className="text-xl font-bold text-gray-800">Transparency</h3>
                        <p className="text-gray-600">Real-time market prices ensure transparency in every transaction.</p>
                    </div>
                    <div className="space-y-4 transform transition duration-300 hover:scale-105">
                        <SiLiquibase className="text-yellow-500 text-5xl mx-auto" />
                        <h3 className="text-xl font-bold text-gray-800">Liquidity</h3>
                        <p className="text-gray-600">Instantly convert your digital gold to cash or physical gold.</p>
                    </div>
                    <div className="space-y-4 transform transition duration-300 hover:scale-105">
                        <GiGoldBar className="text-yellow-500 text-5xl mx-auto" />
                        <h3 className="text-xl font-bold text-gray-800">Purity</h3>
                        <p className="text-gray-600">Only 24K gold with 999.9 purity is stored for you.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DigitalGold;
