import React from 'react'
import bgimg from '../../image/Untitled-1.jpg';

function Deposit() {
    return (
        <div className="relative  rounded-lg shadow-lg max-w-full mx-auto bg-white overflow-hidden">
            <div
                className="absolute inset-0 bg-center filter blur-sm opacity-80"
                style={{ backgroundImage: `url(${bgimg})` }}
            ></div>
           <div className="relative z-10 p-8 ">
                <div className="text-center mb-10">
                    <h1 className="text-3xl font-semibold text-gray-50">Suvarna - Rajat Deposit Scheme</h1>
                    <p className="text-5xl font-bold text-[#FAD562] mt-2">Gold on Gold</p>
                </div>
            <div className="p-6  mb-10">
                    <h6 className="text-xl font-semibold text-gray-50 mb-4">Terms and Conditions</h6>
                    <ul className="list-disc list-inside space-y-2 text-gray-50">
                        <li>You can get the Gold as Interest benefit under this scheme on 99.5% Pure Gold.</li>
                        <li>At the time of opening the account, a minimum of 25 grams of gold deposit is required.</li>
                        <li>The benefit under this scheme will be in the form of 4% interest for the 1st year.</li>
                        <li>To avail this benefit, the customer is required to submit the gold purchase receipts, address proof, & passport size 2 photographs.</li>
                        <li>To get the benefit of the scheme, the customers will be required to keep the gold with JBS for more than 180 days.</li>
                        <li>Deposits or withdrawals from the account or giving benefits will be done in the form of 99.50% gold.</li>
                        <li>The size and nature of the gold returned to the account holder will be different from the size and nature of the gold at the time of deposit.</li>
                        <li>This scheme is available in Baramati, Phaltan, and Pandharpur Branches.</li>
                        <li>For this scheme, while purchasing jewelry, making charges and GST will have to be paid separately.</li>
                        <li>Interest earned under this scheme will be subject to TDS.</li>
                        <li>The right to change, increase, or cancel the terms and conditions shall remain with Jyotichand Bhaichand Saraf & Sons Pvt Ltd.</li>
                        <li>Baramati, Phaltan Branch under Baramati jurisdiction and Pandharpur Branch under Pandharpur jurisdiction.</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Deposit