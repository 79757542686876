import React from 'react'
import bgimg from '../../image/JBS_Pattern.svg';

function Lakshmi() {
    return (
        <div className="relative  rounded-lg shadow-lg max-w-full mx-auto bg-white overflow-hidden">
            <div
                className="absolute inset-0 bg-center filter blur-md"
                style={{ backgroundImage: `url(${bgimg})` }}
            ></div>
            <div className="relative z-10 p-8 ">
                <div className="text-center mb-10">
                    <h2 className="text-3xl font-semibold text-gray-800">Suvarna Lakshmi Yojana</h2>
                    <p className="text-xl font-bold text-[#CB983F] mt-2">Useful For Purchase of Coins, Bullions & Vedhani</p>
                </div>
                <div className="p-6 mb-10">
                    <h6 className="text-xl font-semibold text-gray-800 mb-4">Terms and Conditions</h6>
                    <ul className="list-disc list-inside space-y-2 text-gray-700">
                        <li>After paying Rs. 10,000 in 10 months of Instalments the deposit amount will be  Rs 10,000  On deposit of Rs 10000 Added Benefit  of Rs 700 Will be Given By JBS</li>
                        <li>This saving scheme is exclusively  for Gold  vadhani, coins, and chandi chorsa ( bullions) </li>
                        <li>It is mandatory to submit photo and bank account information at the beginning of the scheme.</li>
                        <li>Only Local Bank Cheque will be accepted on certain dates next month. The date on which the check amount is credited to the institution's bank account will be considered. If cheque is not distributed then, the said Cheque will not be refunded to the bank account. After that, the amount of the card has to be paid in cash.</li>
                        <li>The amount of Instalment  must be paid  Before 10th of every Month</li>
                        <li>Within 10 months only amount can be withdrawn up to the plan closure. Additional after the same period What discount will be given in exchange for the day.</li>
                        <li>At the time of purchase, actual rates and making charges will be charged.</li>
                        <li>If the card is misplaced by customer. After paying Ras 50, another saving will be issued. The same amount will not be refunded under any circumstances. </li>
                        <li>Identity card, saving card and all receipts will have to be submitted at the time of purchase. Similarly, the address filled by you cannot be submitted in the name of another person for any reason.</li>
                        <li>If the customer purchases after completion of the plan period, the account shall be closed on the 360th day from the date of commencement of the plan without giving any discount The principal amount will be transferred into the customer's bank account.</li>
                        <li>Customers will have to pay VAT, VAT, any other actual tax at the time of purchase. Similarly, if any or all of the rules, conditions, taxes etc. are imposed by the Central or State Government Municipalities in this scheme, it shall be paid. Responsibilities of users.</li>
                        <li>This saving plan should be completely excluded. It has nothing to do with past and future plans.</li>
                        <li>Jyotichand Bhaichand Saraf and Sons Pvt ltd reserves the right to modify or change any of the above-mentioned terms and conditions as well as cancel them.</li>
                        <li>Baramati, Phaltan Branch under Baramati jurisdiction and Pandharpur Branch under Pandharpur jurisdiction.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Lakshmi