import React from 'react'
import HomeCategories from './HomeCategories'
import HomeMainCategories from './HomeMainCategories'
import GoldApp from './GoldApp'
import Homepage from './Homepage'

function Home() {
  return (
    <div>
      <Homepage/>
      <HomeMainCategories/>
      <HomeCategories/>
      <GoldApp/>
    </div>
  )
}

export default Home