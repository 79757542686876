import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import About from './Component/About/About';
import Contact from './Component/Contact/Contact';
import Navbar from './Component/Navbar/Navbar';
import Home from './Component/Home/Home';
import Goldapp from './Component/Gold_app/Goldapp';
import SavingSchemes from './Component/Saving_Schemes/SavingSchemes';
import Demo from './Collections/Demo';
import Uppernavbar from './Component/Navbar/Uppernavbar';
import GoldRate from './Component/GoldRate/GoldRate';
import AdminLogin from './Component/Login/AdminLogin';
import Deposit from './Component/Saving_Schemes/Deposit';
import Lakshmi from './Component/Saving_Schemes/Lakshmi';
import Sanchaya from './Component/Saving_Schemes/Sanchaya';
import Footer from './Component/Contact/Footer';

const App = () => {


  return (
    <div>
      <Router>
        <Uppernavbar />
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/JBSapp' element={<Goldapp />} />
          <Route path='/saving_schemes' element={<SavingSchemes />} />
          <Route path='/collections' element={<Demo />} />
          <Route path='/gold_rate' element={<GoldRate />} />
          <Route path='/admin_login' element={<AdminLogin />} />
          <Route path='/savarna-rajat_deposit_scheme' element={<Deposit />} />
          <Route path='/suvarna_lakshmi_yojana' element={<Lakshmi />} />
          <Route path='/suvarna_sanchaya_yojana' element={<Sanchaya />} />

        </Routes>
        <Footer/>
      </Router>
    </div>
  );
};

export default App;
