import React from 'react';
import ContactSection from './ContactSection'; 

const Phaltan = () => (
    <ContactSection 
        address="Siddhivinayak Complex, Near Bus Stand, Phaltan - 415523, Phaltan, Maharashtra"
        mobile="+91 7383036076"
        email="JBS@gmail.com"
        hours={[
            "Monday - Saturday: 9:00 am - 8:00 pm",
            "Sunday: Closed"
        ]}
        mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d121472.26471720843!2d74.75207748507437!3d17.969644391852855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc3b87e7eb4f9d9%3A0x2816bbf3ebf23663!2sSiddhivinayak%20Complex!5e0!3m2!1sen!2sin!4v1693424818314!5m2!1sen!2sin"
    />
);

export default Phaltan;
