import React from 'react'
import img1 from '../../image/home_ring.jpeg'
import img2 from '../../image/home_earing.jpeg'
import img3 from '../../image/home_pendants.jpeg'
import img4 from '../../image/home_bangles.jpeg'
import img5 from '../../image/home_nosepin.jpeg'
import img6 from '../../image/home_bracelate.jpeg'
import img7 from '../../image/home_necklaces.jpeg'
import img8 from '../../image/home_mangalsutra.jpeg'

function HomeCategories() {
    const categories = [
        { img: img1, name: 'RINGS' },
        { img: img2, name: 'EARRINGS' },
        { img: img3, name: 'PENDANTS' },
        { img: img4, name: 'BANGLES' },
        { img: img5, name: 'NOSE PINS' },
        { img: img6, name: 'BRACELETS' },
        { img: img7, name: 'NECKLACES' },
        { img: img8, name: 'MANGALSUTRA' }
    ];

    return (
        <div className="max-w-7xl mx-auto px-4 py-16">
            <div className="text-center mb-12">
                <h1 className="text-3xl font-bold tracking-wide text-gray-800 uppercase">Shop by category</h1>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                {categories.map((category, index) => (
                    <div 
                        key={index} 
                        className="text-center group animate-fadeIn scale-95 hover:scale-100 transition-transform duration-500 ease-in-out"
                    >
                        <div className="w-32 h-32 md:w-40 md:h-40 rounded-full bg-gray-200 mx-auto p-4 flex items-center justify-center shadow-lg transition-transform duration-500 ease-in-out group-hover:scale-110 group-hover:shadow-xl group-hover:animate-bounce">
                            <img 
                                src={category.img} 
                                alt={category.name} 
                                className="w-full h-full object-cover rounded-full" 
                            />
                        </div>
                        <h4 className="mt-4 text-lg font-semibold text-gray-700 group-hover:text-yellow-600 transition-colors duration-300">
                            {category.name}
                        </h4>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default HomeCategories;
