import React from 'react'
import DigitalGold from './DigitalGold'

function Goldapp() {
  return (
    
    <div><DigitalGold/></div>
  )
}

export default Goldapp