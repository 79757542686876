import React from 'react';
import img1 from '../../image/home_gold.jpg';
import img2 from '../../image/home_silver.jpg';
import img3 from '../../image/home_diamond.jpg';

function HomeMainCategories() {
    return (
        <div className="max-w-7xl mx-auto px-4 py-16">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">

                {/* Gold Collection */}
                <div className="group relative overflow-hidden rounded-xl shadow-lg transform transition-all hover:scale-105 hover:shadow-2xl">
                    <div className='transition-transform duration-700 group-hover:scale-110'>
                        <h2 className="text-3xl font-bold text-center mt-8 text-gray-800 uppercase z-40">Gold</h2>
                        <img 
                            src={img1} 
                            alt="Gold Collection" 
                            className="w-full h-64 object-cover rounded-t-xl" 
                        />
                    </div>
                    <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-60"></div>
                    
                    <div className="absolute inset-0 flex justify-start items-end">
                        <h3 className="text-xl font-medium text-gray-300 group-hover:text-[#A3015A] transition-all duration-700 ease-in-out flex items-center justify-center mb-4">
                            <span className="mr-2 group-hover:opacity-100 transition-opacity duration-500 ease-in-out pl-2">Explore Now</span>
                            <svg
                                className="w-6 h-6 transform group-hover:translate-x-2 transition-all duration-700 ease-in-out"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                            </svg>
                        </h3>
                    </div>
                </div>

                {/* Silver Collection */}
                <div className="group relative overflow-hidden rounded-xl shadow-lg transform transition-all hover:scale-105 hover:shadow-2xl">
                    <div className='transition-transform duration-700 group-hover:scale-110'>
                        <h2 className="text-3xl font-bold text-center mt-8 text-gray-800 uppercase z-40">Silver</h2>
                        <img 
                            src={img2} 
                            alt="Silver Collection" 
                            className="w-full h-64 object-cover rounded-t-xl" 
                        />
                    </div>
                    <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-60"></div>
                    
                    <div className="absolute inset-0 flex justify-start items-end">
                        <h3 className="text-xl font-medium text-gray-300 group-hover:text-[#A3015A] transition-all duration-700 ease-in-out flex items-center justify-center mb-4">
                            <span className="mr-2 group-hover:opacity-100 transition-opacity duration-500 ease-in-out pl-2">Explore Now</span>
                            <svg
                                className="w-6 h-6 transform group-hover:translate-x-2 transition-all duration-700 ease-in-out"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                            </svg>
                        </h3>
                    </div>
                </div>

                {/* Diamond Collection */}
                <div className="group relative overflow-hidden rounded-xl shadow-lg transform transition-all hover:scale-105 hover:shadow-2xl">
                    <div className='transition-transform duration-700 group-hover:scale-110'>
                        <h2 className="text-3xl font-bold text-center mt-8 text-gray-800 uppercase z-40">Diamond</h2>
                        <img 
                            src={img3} 
                            alt="Diamond Collection" 
                            className="w-full h-64 object-cover rounded-t-xl" 
                        />
                    </div>
                    <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-60"></div>
                   
                    <div className="absolute inset-0 flex justify-start items-end">
                        <h3 className="text-xl font-medium text-gray-300 group-hover:text-[#A3015A] transition-all duration-700 ease-in-out flex items-center justify-center mb-4">
                            <span className="mr-2 group-hover:opacity-100 transition-opacity duration-500 ease-in-out pl-2">Explore Now</span>
                            <svg
                                className="w-6 h-6 transform group-hover:translate-x-2 transition-all duration-700 ease-in-out"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                            </svg>
                        </h3>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default HomeMainCategories;
